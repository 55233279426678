import { useNavigate } from "react-router-dom";

import logo from "./images/logoascnedify.png";
// import { ReactComponent as BlacksLogo } from "./images/logo.svg";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-scroll";
import "./styles/global.scss";
import { useEffect, useState } from "react";

export default function Header({ showContactUsButton = true }) {
  const [headerClass, setHeaderClass] = useState("");
  const [showMenu, setShowMenu] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setHeaderClass(window.scrollY > 400 ? "in-sticky" : "");
    });
  });

  const getStartedClicked = () => {
    // window.open("https://forms.gle/YmCDVapAXkFn5mNX8", "_blank");
    // window.open(
    //   "https://share.hsforms.com/1CqbHol8gSROWQ9s8RhlUxwctsk7",
    //   "_blank"
    // );
    navigate("/contact");
  };

  const headerLinksClicked = (target) => {
    if (
      window.location.pathname.includes("/blog") ||
      window.location.pathname.includes("/contact")
    ) {
      navigate(`/?t=${target}`);
    }
  };

  return (
    <header className={headerClass}>
      <div className="header-inner">
        <div style={{width: '200px', height: '150px', marginTop:'-30px'}}>
          <img src={logo} width={"100%"} height={"100%"} onClick={() => navigate("/")}/>
        </div>
        <Navbar expand={"lg"}>
          <nav className="desktop-menu">
            <ul>
              <li>
                <Link
                  className="link"
                  to="about-us-holder"
                  // spy={true}
                  smooth={true}
                  onClick={() => headerLinksClicked("about")}
                  offset={-40}
                  duration={1000}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  to="service-holder"
                  // spy={true}
                  smooth={true}
                  onClick={() => headerLinksClicked("services")}
                  offset={-40}
                  duration={1000}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  to="why-block-holder"
                  // spy={true}
                  onClick={() => headerLinksClicked("why-us")}
                  smooth={true}
                  offset={-40}
                  duration={1000}
                >
                  Why Us
                </Link>
              </li>
              {/* <li>
                <Link
                  className="link"
                  onClick={() => navigate("/blog")}
                  smooth={true}
                  offset={-40}
                  duration={1000}
                >
                  Blogs
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  onClick={() => navigate("/case-studies")}
                  smooth={true}
                  offset={-40}
                  duration={1000}
                >
                  Case Studies
                </Link>
              </li> */}
            </ul>
            {showContactUsButton ? (
              <Button
                variant="primary bs-primary-button"
                size="lg"
                onClick={getStartedClicked}
              >
                Contact Us
              </Button>
            ) : null}
          </nav>
          <Navbar.Toggle onClick={()=> setShowMenu(!showMenu)} aria-controls="offcanvasNavbar" />
          {showMenu && <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-1">
                <Nav.Link>
                  <Link
                    className="link"
                    to="about-us-holder"
                    // spy={true}
                    onClick={() => headerLinksClicked("about")}
                    smooth={true}
                    offset={-40}
                    duration={1000}
                  >
                    About
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    className="link"
                    to="service-holder"
                    // spy={true}
                    onClick={() => headerLinksClicked("services")}
                    smooth={true}
                    offset={-40}
                    duration={1000}
                  >
                    Services
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    className="link"
                    to="why-block-holder"
                    // spy={true}
                    onClick={() => headerLinksClicked("why-us")}
                    smooth={true}
                    offset={-40}
                    duration={1000}
                  >
                    Why Us
                  </Link>
                </Nav.Link>
                {/* <Nav.Link>
                  <Link
                    className="link"
                    onClick={() => navigate("/case-studies")}
                    smooth={true}
                    offset={-40}
                    duration={1000}
                  >
                    Case Studies
                  </Link>
                </Nav.Link> */}
                {showContactUsButton ? (
                  <Nav.Link>
                    <Button
                      variant="primary bs-primary-button"
                      size="lg"
                      onClick={getStartedClicked}
                    >
                      Contact Us
                    </Button>
                  </Nav.Link>
                ) : null}
              </Nav>
            </Offcanvas.Body>
            <p className="slide-nav-p">© 2022 Ascendify Technologies Ltd. </p>
                </Navbar.Offcanvas>}
        </Navbar>
      </div>
    </header>
  );
}
