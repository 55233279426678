import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import * as contact from "../lotties/contact.json";
import Header from "../Header";
import "../styles/global.scss";
import Footer from "../Footer";
import axios from "axios";
import { baseUrl } from "../appConfig";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  const [contactForm, setContactForm] = useState({ isReceiveEmails: true });
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
  };

  function onSubmit(event) {
    event.preventDefault();
    const {
      firstName,
      lastName,
      email,
      companyName,
      phone,
      description,
      isReceiveEmails,
    } = contactForm;

    const data = {
      type: 'inquiry',
      firstName,
      lastName,
      email,
      companyName,
      phone,
      description,
      isReceiveEmails,
    };

    console.log("Inquiry Data: ", data); 

    setIsLoading(true);
    axios
      .post(`${baseUrl}/send-email`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(({ data }) => {
        setIsLoading(false);
        alert(
          "Your information has been saved successfully. We will get back to you soon."
        );
        navigate("/");
      })
      .catch((error) => {
        alert(JSON.stringify(error));
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="inner-page-header">
        <Header showContactUsButton={false}></Header>
      </div>
      <main className="main">
        <div className="contact-page">
          <div className="container">
            <div className="mian-head">
              <h1>Contact Us</h1>
            </div>
            <Row className="flex-column-reverse flex-md-row">
              <Col md={6} xxl={8}>
                <Form className="contact-form" onSubmit={onSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-5" controlId="">
                        <Form.Control
                          required
                          type="text"
                          placeholder="First Name"
                          value={contactForm.firstName || ''}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-5" controlId="">
                        <Form.Control
                          type="text"
                          required
                          placeholder="Last Name"
                          value={contactForm.lastName || ''}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-5" controlId="">
                        <Form.Control
                          type="email"
                          required
                          placeholder="Email"
                          value={contactForm.email || ''}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              email: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-5" controlId="">
                        <Form.Control
                          required
                          type="phone"
                          placeholder="Phone"
                          value={contactForm.phone || ''}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              phone: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-5" controlId="">
                        <Form.Control
                          required
                          type="text"
                          placeholder="Company Name"
                          value={contactForm.companyName || ''}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              companyName: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-4" controlId="">
                        <Form.Control
                          required
                          as="textarea"
                          placeholder="Tell us about your Project"
                          rows={6}
                          value={contactForm.description || ''}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              description: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-5" controlId="">
                        <div className="mb-3">
                          <Form.Check
                            checked={contactForm.isReceiveEmails}
                            type="checkbox"
                            label="Yes, I would like to receive the latest content from Ascendify Technologies Software Solutions."
                            id=""
                            onChange={(e) => {
                              setContactForm({
                                ...contactForm,
                                isReceiveEmails: e.target.checked,
                              });
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Button
                        className="float-end"
                        variant="primary bs-primary-button"
                        size="lg"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md={6} xxl={4}>
                <div className="contact-lotti-animation">
                  <Lottie
                    options={{ ...defaultOptions, animationData: contact }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="projects-and-clients">
          <Container>
              {/* <div className="successful-projects">
              <div className="main-head">
                <h2>
                  we completed 1200+ projects yearly
                  <br /> successfully & counting
                </h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its
                  <br /> layout. The point of using Lorem Ipsum is that it has a
                  more-or-less.
                </p>
              </div>
              <Row className="acounter">
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={3273} />
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Projects Complete</h4>
                </Col>
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={2560} />
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Active Projects</h4>
                </Col>
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={323} />
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Client Satisfied</h4>
                </Col>
                <Col xs={6} md={3}>
                  <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                    {({ isVisible }) => (
                      <div className="counter-numbers">
                        {isVisible ? (
                          <CountUp className="acounter-strong" end={54} />
                        ) : null}
                        <i className="plus-ac">+</i>
                      </div>
                    )}
                  </VisibilitySensor>
                  <h4>Country Available</h4>
                </Col>
              </Row>
            </div>
            <div className="our-clients">
              <h2>Our Trusted Clients</h2>
              <div className="client-logos">
                <img
                  src={require("./images/clients-logos.png")}
                  alt="Clients Logos"
                />
              </div>
            </div> */}
            {/* <div className="contact-us">
              <div className="small-plant-animation left">
                <Lottie
                  options={{ ...defaultOptions, animationData: SmallPlant }}
                />
              </div>
              <Row className="align-items-center justify-content-md-center">
                <Col sm={12} md={6} lg={6}>
                  <div className="content">
                    <h2>Contact Us!</h2>
                    <p>
                      Get in touch today to discover what we can do for you.
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <Form className="search-bar">
                    <Form.Group controlId="">
                      <Form.Control
                        type="email"
                        placeholder="Enter Your email"
                      />
                    </Form.Group>
                    <Button
                      variant="primary bs-primary-button"
                      size="sm"
                      type="submit"
                    >
                      Send
                    </Button>
                  </Form>
                </Col>
              </Row>
              <div className="small-plant-animation right">
                <Lottie
                  options={{ ...defaultOptions, animationData: SmallPlant }}
                />
              </div>
            </div> */}
          </Container>
        </div>
      </main>
      <Footer></Footer>
      <div className={`page-loader ${isLoading ? "true" : ""}`}>
        <div className="loader">
          <div className="circle green"></div>
          <div className="circle orange"></div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
