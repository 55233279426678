import moment from "moment";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import Container from "react-bootstrap/esm/Container";
import {
  FaShareAlt,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import { CONTENTFUL_URL } from "../constants";
import { useNavigate } from "react-router-dom";

import {
  generateItemObject,
  generateItemObjectArray,
} from "contentful-response-parser";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function CaseStudyDetail() {
  let navigate = useNavigate();

  const { title } = useParams();

  const [blogDetails, setBlogDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getImage = (section) => {
    return (
      <figure className="mb-5">
        <img src={section?.image?.url} alt="" />
      </figure>
    );
  };

  const getBlogDetails = async () => {
    // const str = title.replaceAll("-", " ");
    const str = title;
    try {
      const { data } = await axios.get(
        `${CONTENTFUL_URL}&content_type=blogPost&fields.title=${str}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const parsed = generateItemObject(data);
      for (let section of parsed.blogSections) {
        section.html = documentToHtmlString(section.paragraph);
      }

      // const html = documentToHtmlString(parsed.blogSections[0].paragraph); // -> <p>Hello world!</p>

      parsed.categories = parsed.category.map((i) => i.category);
      console.log(data);

      try {
        const lastUpdatedAt = data.items[0].sys.updatedAt;
        parsed.updatedAt = moment(lastUpdatedAt).format("LL");
      } catch (error) {
        parsed.updatedAt = moment().subtract(10, "days").format("LL");
      }

      setBlogDetails(parsed);
    } catch (error) {
      console.log(JSON.stringify(error));
      // alert("Something went wrong while loading blog, please try again later");
      // navigate("/blog");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBlogDetails();
  }, []);

  function createMarkup(html) {
    return { __html: html };
  }

  return (
    <>
      <div className="inner-page-header">
        <Header></Header>
      </div>
      <main className="main blog-detail">
        <Container>
          <div className="page-head">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/case-studies">Case Studies</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {blogDetails?.title}
              </li>
            </ul>
            <h2>{blogDetails?.title}</h2>
            <ul className="share-list">
              <li>
                {blogDetails?.updatedAt}
                {/* by {blogDetails.publishedBy} */}
              </li>
              <li>
                {blogDetails?.categories?.map((c, index) => (
                  <span>
                    {c}
                    {index + 1 < blogDetails.categories.length ? "," : ""}{" "}
                  </span>
                ))}
              </li>
            </ul>
          </div>
          {blogDetails?.blogSections?.map((section) => {
            return (
              <>
                <h3 className="mb-2">{section?.title}</h3>
                <div dangerouslySetInnerHTML={createMarkup(section.html)} />
                {getImage(section)}
              </>
            );
          })}
          <br></br>
          <br></br>

          {/* <ul className="social-icons mb-5">
            <li>
              <a href="">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="">
                <FaYoutube />
              </a>
            </li>
            <li>
              <a href="">
                <FaTwitter />
              </a>
            </li>
          </ul> */}
          <h2 className="mb-4">Related posts</h2>
          <div className="mb-5">
            <Row>
              {blogDetails?.relatedPosts?.map((rp) => (
                <Col sm={12} md={6} lg={3}>
                  <div className="post-grid mb-4">
                    <figure className="mb-2">
                      <img src={rp.thumbnail.url} alt="" />
                    </figure>
                    <div className="detail">
                      <h5>
                        <a
                          href={`/blog/${encodeURIComponent(
                            rp.thumbnail.title
                          )}`}
                        >
                          {rp.title}
                        </a>
                      </h5>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          {/* <h2 className="mt-4 mb-5">Comments</h2>
          <Form className="contact-form mb-5">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-5" controlId="">
                  <Form.Control required type="text" placeholder="First Name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-5" controlId="">
                  <Form.Control type="text" required placeholder="Last Name" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-4" controlId="">
                  <Form.Control
                    required
                    as="textarea"
                    placeholder="Tell us about your Project"
                    rows={6}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-5" controlId="">
                  <div className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Yes, I would like to receive the latest content from Ascendify Technologies."
                      id=""
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Button
                  className="float-end"
                  variant="primary bs-primary-button"
                  size="lg"
                  type="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <div className="comments-list">
            <div className="comment">
              <figure>
                <img src={require("../images/dummy-user.png")} alt="" />
              </figure>
              <div className="detail">
                <h5 className="user-name">Nick Jhons</h5>
                <span className="date">20 Aug 2022</span>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </p>
                <a className="btn-link" href="">
                  Replay
                </a>
              </div>
            </div>
            <div className="comment replay">
              <figure>
                <img src={require("../images/dummy-user.png")} alt="" />
              </figure>
              <div className="detail">
                <h5 className="user-name">Nick Jhons</h5>
                <span className="date">20 Aug 2022</span>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </p>
                <a className="btn-link" href="">
                  Replay
                </a>
              </div>
            </div>
            <div className="comment">
              <figure>
                <img src={require("../images/dummy-user.png")} alt="" />
              </figure>
              <div className="detail">
                <h5 className="user-name">Nick Jhons</h5>
                <span className="date">20 Aug 2022</span>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </p>
                <a className="btn-link" href="">
                  Replay
                </a>
              </div>
            </div>
            <div className="comment">
              <figure>
                <img src={require("../images/dummy-user.png")} alt="" />
              </figure>
              <div className="detail">
                <h5 className="user-name">Nick Jhons</h5>
                <span className="date">20 Aug 2022</span>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </p>
                <a className="btn-link" href="">
                  Replay
                </a>
              </div>
            </div>
          </div> */}
        </Container>
      </main>
      <Footer></Footer>
      <div className={`page-loader ${isLoading ? "true" : ""}`}>
        <div class="loader">
          <div class="circle green"></div>
          <div class="circle orange"></div>
        </div>
      </div>
    </>
  );
}

export default CaseStudyDetail;
