import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Slider from "react-slick";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import Row from "react-bootstrap/esm/Row";
import { useEffect, useState } from "react";
import {
  generateItemObject,
  generateItemObjectArray,
} from "contentful-response-parser";
import { CONTENTFUL_URL } from "../constants";

function BlogList() {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [thumbs, setThumbs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(searchParams.get('category'));

  const settings = {
    className: "filter-list",
    dots: false,
    infinite: false,
    slidesToShow: categories.length < 5 ? categories.length : 5,
    slidesToScroll: 2,
    nextArrow: <FaChevronRight />,
    prevArrow: <FaChevronLeft />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: categories.length < 5 ? categories.length : 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: categories.length < 5 ? categories.length : 5,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: categories.length < 3 ? categories.length : 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: categories.length < 2 ? categories.length : 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const getThumbnails = async () => {
    try {
      const { data } = await axios.get(
        `${CONTENTFUL_URL}&content_type=blogThumbnail`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const parsed = generateItemObjectArray(data);
      const thumbs = parsed.map((t) => {
        t.categories = t.category.map((c) => c.category);
        return t;
      });
      setThumbs(thumbs.filter((t) => !t.isAPortfolioCaseStudy));
    } catch (error) {
      alert(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCategories = () => {
    axios
      .get(`${CONTENTFUL_URL}&content_type=categories`, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        const parsed = generateItemObjectArray(data);
        const categories = parsed.map((i) => i.category).sort();
        setCategories(categories);
        setSelectedCategory(selectedCategory || categories[0]);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  useEffect(() => {
    getAllCategories();
    getThumbnails();
    window.scrollTo(0, 0);
  }, []);

  // const getCategoryFiltered = () =>
  //   thumbs.filter((t) => t.categories.includes(selectedCategory));

  const getFeaturedAndCategoryFiltered = (isFeatured) =>
    thumbs
      .filter((t) => t.categories.includes(selectedCategory))
      .filter((t) => (isFeatured ? t.isFeaturedTopic : !t.isFeaturedTopic));

  return (
    <>
      <div className="inner-page-header">
        <Header></Header>
      </div>
      <div className="inner-page-banner">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Blog
          </li>
        </ul>
        <h1>News & Updates</h1>
      </div>
      <main className="main blog-list-page">
        <div className="filter-list-holder">
          <Container>
            <Slider {...settings}>
              {categories.map((c) => (
                <div
                  onClick={() => {
                    setSelectedCategory(c);
                    navigate(`/blog?category=${c}`);

                  }}
                  className={selectedCategory === c ? "slick-current" : ""}
                >
                  <a href="javascript:void(0)" >{c}</a>
                </div>
              ))}
            </Slider>
          </Container>
        </div>
        <div className="blog-list-holder">
          <Container>
            <Row>
              <Col md={6}>
                {getFeaturedAndCategoryFiltered(false).map((t) => (
                  <div
                    style={{ cursor: "pointer" }}
                    className="md-blog-post"
                    onClick={() => {
                      // navigate(
                      //   `/blog/${t.thumbnail.title.replaceAll(" ", "-")}`
                      // );
                      navigate(
                        `/blog/${encodeURIComponent(t.thumbnail.title)}`
                      );
                    }}
                  >
                    <figure>
                      <img src={t.thumbnail.url} alt="" />
                    </figure>
                    <div className="detail">
                      <h4>{t.thumbnail.title}</h4>
                    </div>
                  </div>
                ))}
              </Col>
              <Col md={6}>
                {getFeaturedAndCategoryFiltered(true).length ? (
                  <h3 className="featured-list-title">Featured topics</h3>
                ) : null}

                {getFeaturedAndCategoryFiltered(true).map((t) => (
                  <div
                    style={{ cursor: "pointer" }}
                    className="list-post-style"
                    onClick={() => {
                      // navigate(
                      //   `/blog/${t.thumbnail.title.replaceAll(" ", "-")}`
                      // );
                      navigate(
                        `/blog/${encodeURIComponent(t.thumbnail.title)}`
                      );
                    }}
                  >
                    <figure>
                      <img src={t.thumbnail.url} alt="" />
                    </figure>
                    <div className="detail">
                      <h5>{t.thumbnail.title}</h5>
                      <p>{t.thumbnail.description}</p>
                      <a
                        className="link-btn"
                        href={`/blog/${encodeURIComponent(t.thumbnail.title)}`}
                      >
                        Read More <FaChevronRight />
                      </a>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
        {/* <Container className="pagination-holder">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#">
                Previous
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                1
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                2
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                3
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </Container> */}
      </main>
      <Footer></Footer>
      <div className={`page-loader ${isLoading ? "true" : ""}`}>
        <div class="loader">
          <div class="circle green"></div>
          <div class="circle orange"></div>
        </div>
      </div>
    </>
  );
}

export default BlogList;
